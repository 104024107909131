import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypeById, getTicketTypeInfosByTicketTypeId, applyPromotionCode } from "ticketino-api-client";
import parse from "html-react-parser";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import icon_mail from "../img/payments/mail.svg"
import icon_printer from "../img/payments/printer.svg"
import icon_paypal from "../img/payments/paypal.svg"
import icon_mastercard from "../img/payments/mastercard.svg"
import icon_visa from "../img/payments/visa.svg"
import icon_amex from "../img/payments/amex.svg"
import icon_twint from "../img/payments/twint.svg"
import icon_postfinance from "../img/payments/postfinance.svg"
import icon_invoice from "../img/payments/invoice.svg"
import imgClose from '../img/icon-close.svg';

import imgIconProgress4 from '../img/icon-progress4.svg';
import imgIconProgressTick from '../img/icon-progress-tick.svg';

import "../css/style.css";
import "../css/shipping-payment.css";

const Step30_Checkout = () => {
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [promotioncode, setPromotioncode] = useState("");
    const [promotionError, setPromotionError] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [entryTime, setEntryTime] = useState("");
    const [order, setOrder] = useState({});
    const [tickets, setTickets] = useState([]);
    const [notAllowedPromotionCodes, setNotAllowedPromotionCodes] = useState([]);
    const [payment, setPayment] = useState({
        paymentMethodId: 0,
        deliveryMethodId: 0,
        insurance: false
    })

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        // gtm - addPaymentInfo
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'addPaymentInfo'
        });
    }, [])

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    //useEffect(() => {
    //    addPromotionToOrder(promotioncode)
    //}, [promotioncode]);

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.checkOut);
            })
            .catch((error) => console.error(error.response.data));
    };

    const formattedDate = (date) => {
        let localLanguageString = 'de-DE';

        if (language === "en") {
            localLanguageString = 'en-US'
        }
        else if (language === "fr") {
            localLanguageString = 'fr-FR'
        }

        let formattedDate = date.toLocaleDateString(localLanguageString, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        return formattedDate;
    }

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            const bookedTickets = updatedOrder.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {

                    if (ticket.timeOfEntryFrom && ticket.timeOfEntryTo) {
                        setEntryTime(`${ticket.timeOfEntryFrom.slice(0, -3)} - ${ticket.timeOfEntryTo.slice(0, -3)}`)
                    }

                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    var date = new Date(ticketType.start);
                    setEventDate(formattedDate(date));

                    const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
                })
            )

            let updatedPayment = { ...payment, deliveryMethodId: updatedOrder.deliveryMethodId, paymentMethodId: updatedOrder.paymentMethodId };

            updatedPayment.insurance = updatedOrder.totalInsuranceAmount > 0;

            setPayment(updatedPayment);
            setOrder(updatedOrder);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const changeDeliveryType = async (deliveryMethodId) => {
        await axios.put(
            `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryMethodId}`)
            .then(() => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const changePaymentType = (paymentMethodId) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentMethodId}`)
            .then((res) => {
                loadOrder(orderId);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const removeTicket = async (ticketId) => {
        if (order != null && order.tickets != null) {
            let ticketTypeIdsToRemove = order.tickets
                .filter(ticket => ticket.id === ticketId)
                .map(ticket => ticket.id);

            // DELETE request to remove tickets
            await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                data: { TicketsToRemove: ticketTypeIdsToRemove }
            }).then((res) => {
                loadOrder(order.id)
            })
        }
    };

    const onSubmit = async () => {
        if (order?.totalPrice > 0) {
            let dataTransProviders = [1, 7, 8, 9, 10, 14];

            let datatransProvider = dataTransProviders.findIndex((d) => d === payment.paymentMethodId) !== -1

            if (datatransProvider) {
                startDatatrans();
            }
        } else {
            changePaymentTypeToFree();
        }
    }

    const setOrderInsurance = async () => {
        await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then(() => {
                loadOrder(orderId);
            }).catch((error) => {
                console.error(error);
            })
    }

    const removeOrderInsurance = async () => {
        await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then(() => {
                loadOrder(orderId);
            }).catch((error) => {
                console.error(error);
            })
    }

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {

                await confirmShopbasketByOrderId(orderId);

                //we don't need that anymore
                sessionStorage.removeItem("OrderId");

                navigate(`/${language}/confirmation/${orderId}`);
            });
    }

    const addPromotionToOrder = async () => {

        setPromotionError("");

        let promotionCodesNotAllowed = [];

        if (notAllowedPromotionCodes == null || notAllowedPromotionCodes?.length < 1) {
            const res = await axios.get(`form/formsettings`);

            promotionCodesNotAllowed = res.data.notAllowedPromotionCodes;

            setNotAllowedPromotionCodes(promotionCodesNotAllowed);
        }
        else {
            promotionCodesNotAllowed = notAllowedPromotionCodes;
        }

        if (promotionCodesNotAllowed.includes(promotioncode.toLowerCase())) {
            setPromotionError(resources?.PromoCodeNotAllowed);
            return;
        }

        try {
            await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotioncode}`);
            loadOrder(orderId);
        } catch (error) {

            setPromotionError(error.response.data);
        }
    }

    const mapTickets = () => {
        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.id - b.id);
        }

        return (tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((t, index) => (
                (<div key={index} className="row pt-2 pb-2">
                    <div className="col-6 col-md-7 text-20-24 black-color text-start d-flex align-items-center">
                        <label>
                            1 x {t.info?.name}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                        <label>
                            {t.currency}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-start align-items-center">
                        <label className="price-label text-end">
                            {t.price.toFixed(2)}
                        </label>
                    </div>
                    <div className="col-4 col-md-1 text-20-24 black-color d-flex justify-content-end align-items-center">
                        <button
                            type="button"
                            className="ticket-remove-btn"
                            aria-label="Close"
                            onClick={() => removeTicket(t.id)} >
                            <img src={imgClose} alt="X" />
                        </button>
                    </div>

                </div>)
            ))
        );
    }

    const mapExtraCosts = () => {
        return (
            <div>
                <div className="row pt-2 pb-2">
                    <div className="col-6 col-md-7 text-20-24 black-color text-start d-flex align-items-center">
                        <label>
                            {resources?.DeliveryCost}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                        <label>
                            {order.currency}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-start align-items-center">
                        <label className="price-label text-end">
                            {order?.deliverPaymentFee.toFixed(2)}
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    const mapInsurance = () => {
        return (
            <div className="row pt-2 pb-2">
                <div className="col-6 col-md-7 text-20-24 black-color text-start d-flex align-items-center">
                    <label>
                        {resources.TicketInsurance}
                    </label>
                </div>
                <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                    <label>
                        {order.currency}
                    </label>
                </div>
                <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-start align-items-center">
                    <label className="price-label text-end">
                        {order.totalInsuranceAmount.toFixed(2)}
                    </label>
                </div>
                <div className="col-4 col-md-1 text-20-24 black-color d-flex justify-content-end align-items-center">
                </div>

            </div>
        );
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={true} pagePath="checkout" />
            <main className="flex-fill">
                <div id="datatrans-form-placeholder"></div>
                <div className="container page-wrapper mt-4">
                    <>
                        <div>
                            <div className="progress-container">
                                <div className="progress-step">
                                    <div>
                                        <div className="image cursor-pointer ">
                                            <img src={imgIconProgressTick} alt="Step 1" onClick={() => navigate(-3)} />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.DateAndTime}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step completed">
                                    <div>
                                        <div className="image cursor-pointer ">
                                            <img src={imgIconProgressTick} alt=" Step 2" onClick={() => navigate(-2)} />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.TicketSelection}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step">
                                    <div>
                                        <div className="image cursor-pointer ">
                                            <img src={imgIconProgressTick} alt="Step 3" onClick={() => navigate(-1)} />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.SummaryAndContact}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step active">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgress4} alt="Step 4" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.PaymentAndDelivery}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 mb-1">
                                    <p className="text-50-55 black-color font-500 black-color">{resources.Delivery}</p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="form-check mb-3 d-flex align-items-center">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="rbtPrintAtHome"
                                        checked={payment.deliveryMethodId === 1 ? true : false}
                                        onClick={() => changeDeliveryType(1)}
                                        id="printAtHome"
                                    />
                                    <label className="form-check-label delivery-type d-flex align-items-center" htmlFor="printAtHome" >
                                        <img src={icon_printer} alt="PrintAtHome Icon" className="me-2" />
                                        <div className="ms-2">
                                            <p className="m-0 text-20-24">{resources.DeliveryPrintAtHome}</p>
                                            <p className="m-0 text-secondary text-16-20">
                                                {resources.DeliveryNoFee}
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div className="form-check mb-3 d-flex align-items-center">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="rbtPostMail"
                                        checked={payment.deliveryMethodId === 2 ? true : false}
                                        onClick={() => changeDeliveryType(2)}
                                        id="postMail"
                                    />
                                    <label className="form-check-label delivery-type d-flex align-items-center" htmlFor="printAtHome" >
                                        <img src={icon_mail} className="me-2" alt="PostMail Icon" />
                                        <div className="ms-2">
                                            <p className="m-0 text-20-24">{resources.DeliveryPost}</p>
                                            <p className="m-0 text-secondary text-16-20">
                                                {resources.DeliveryFee}
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            {
                                order.totalPrice > 0 &&
                                <>
                                    <div className="row">
                                        <div className="col-md-12 mt-4 mb-1">
                                            <p className="text-50-55 black-color font-500">{resources?.PaymentTitle}</p>
                                        </div>
                                    </div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtTwint"
                                            checked={payment.paymentMethodId === 14 ? true : false}
                                            onClick={() => changePaymentType(14)}
                                            id="twint"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="twint" >
                                            <img htmlFor="twint" src={icon_twint} className="me-2" alt="Twint Icon" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.Twint}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.TwintDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtMastercard"
                                            checked={payment.paymentMethodId === 1 ? true : false}
                                            onClick={() => changePaymentType(1)}
                                            id="mastercard"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="mastercard" >
                                            <img htmlFor="mastercard" src={icon_mastercard} className="me-2" alt="Twint Mastercard" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.MasterCard}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.MasterCardDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtVisa"
                                            checked={payment.paymentMethodId === 7 ? true : false}
                                            onClick={() => changePaymentType(7)}
                                            id="visa"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="visa" >
                                            <img htmlFor="visa" src={icon_visa} className="me-2" alt="Visa Icon" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.Visa}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.VisaDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtAmex"
                                            checked={payment.paymentMethodId === 8 ? true : false}
                                            onClick={() => changePaymentType(8)}
                                            id="amex"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="amex" >
                                            <img htmlFor="amex" src={icon_amex} className="me-2" alt="Amex Icon" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.AmericanExpress}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.AmericanExpressDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtPostfinance"
                                            checked={payment.paymentMethodId === 9 ? true : false}
                                            onClick={() => changePaymentType(9)}
                                            id="postfinance"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="postfinance" >
                                            <img htmlFor="amex" src={icon_postfinance} className="me-2" alt="PostFinance Icon" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.PostFinance}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.PostFinanceDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="form-check mb-3 d-flex align-items-center">
                                        <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            name="rbtPaypal"
                                            checked={payment.paymentMethodId === 10 ? true : false}
                                            onClick={() => changePaymentType(10)}
                                            id="paypal"
                                        />
                                        <label
                                            className="form-check-label payment-type d-flex align-items-center" htmlFor="paypal" >
                                            <img htmlFor="paypal" src={icon_paypal} className="me-2" alt="PostFinance Icon" />
                                            <div className="ms-2">
                                                <p className="m-0 text-20-24">{resources.PayPal}</p>
                                                <p className="m-0 text-secondary text-16-20">
                                                    {resources.PayPalDesc}
                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                </>
                            }

                            {order.totalPrice > 0 &&
                                <div className="row mt-5">
                                    <div className="col-md-12 mb-3">
                                        <div className="form-check mb-4">
                                            <input
                                                className="form-check-input me-3 "
                                                type="checkbox"
                                                checked={payment.insurance}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setOrderInsurance();
                                                    } else {
                                                        removeOrderInsurance();
                                                    }
                                                }}
                                            />
                                            <label
                                                className="col-11 form-check-label fs-6"
                                                for="inlineCheckbox1"
                                            > <p className="m-0 text-16-18 font-900 ">{resources.TicketInsurance}</p>
                                                <p className="m-0 text-16-20" dangerouslySetInnerHTML={{ __html: resources?.InsuraceText }}></p>
                                            </label>
                                        </div>

                                    </div>
                                </div>}

                            <div className="row">
                                <p className="text-50-55 black-color font-500">{resources.PromoCodes}</p>
                                <p className="text-16-18 black-color">{resources.PromocodeText}</p>
                                <div className="col-md-8 col-12 mb-3 text-20-24">
                                    <input className="form-control" placeholder={resources.PromoCode} value={promotioncode}
                                        onChange={(e) => setPromotioncode(e.target.value)} />
                                </div>
                                <div className="col-md-4 col-12 mb-3 text-start">
                                    <div className="text-start ">
                                        <button className="btn grey-button" onClick={() => addPromotionToOrder()}>{resources.ApplyPromocode}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-16-18 red-color">
                                    <p>{promotionError}</p>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <p className="text-50-55 black-color font-500">{resources.Summary}</p>
                                </div>
                            </div>
                            {eventDate !== "" && eventDate && entryTime !== "" && <div className="row mt-3 mb-3">
                                <div className="col-md-12 div-container-blue">
                                    <p className="text-20-24 font-500 black-color mt-3">{eventDate !== "" && eventDate} {entryTime !== "" && (entryTime + " " + resources?.SummaryHourText + " " + resources?.SummaryTimeFormat)}</p>
                                </div>
                            </div>}

                            {mapTickets()}
                            {payment.insurance && mapInsurance()}
                            {order.deliverPaymentFee > 0 && mapExtraCosts()}
                            <div className="mt-3 grey-line" ></div>
                            <div className="row mt-3 mb-5">
                                <div className="col-md-7 col-6 text-20-24 black-color d-flex justify-content-start align-items-center">{resources.Total}:</div>
                                <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                                    <label className="font-600">
                                        {order.currency}
                                    </label>
                                </div>
                                <div className="col-1 col-md-1 text-20-24 black-color d-flex justify-content-start align-items-center">
                                    <label className="font-600 price-label text-end"> {order && order.totalPrice && order.totalPrice !== null ? (
                                        <span>{(order.totalPrice).toFixed(2)}</span>
                                    ) : (
                                        <span>0.00</span>
                                    )}
                                    </label>
                                </div>
                            </div>
                            <div>
                                <p className="mb-4">{parse(resources.AGB ?? "")}</p>
                            </div>
                            <div className="row mt-5 margin-bottom-55">
                                <div className="col-6">
                                    <button className="btn transperant-grey-button" onClick={() => { navigate(-1); }}>
                                        {resources?.Back}
                                    </button>
                                </div>
                                <div className="col-6 text-end">
                                    <button
                                        className="btn  grey-button"
                                        onClick={onSubmit}>{resources.FinishOrderWithPrice}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                    <Footer />
                </div>
            </main>
        </div>
    )
}

export default Step30_Checkout