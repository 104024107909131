import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypeInfosByTicketTypeId, getTicketTypeById, getEventInfosByEventId } from "ticketino-api-client";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

import imgIconProgress3 from '../img/icon-progress3.svg';
import imgIconProgressGrey from '../img/icon-progress-grey.svg';
import imgIconProgressTick from '../img/icon-progress-tick.svg';
import imgClose from '../img/icon-close.svg';

import "../css/style.css";
import "../css/shipping-payment.css";

const Step20_Shipping = () => {

    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState({});
    const [countries, setCountries] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [token] = useState(sessionStorage.getItem("token"));
    const [loading, setLoading] = useState(true);
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
    const [eventDate, setEventDate] = useState("");
    const [entryTime, setEntryTime] = useState("");

    const [showMariaAbramovicEvent, setShowMariaAbramovicEvent] = useState(false);
    const [showWongVanGoghEvent, setShowWongVanGoghEvent] = useState(false);
    const [showCollectionEvent, setShowCollectionEvent] = useState(false);

    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        email: "",
        confirmemail: ""
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
            loadCountries();
        }
    }, [orderId]); //gets called when an order is started

    useEffect(() => {
        if (order.tickets && order.tickets.length === 0) {
            navigate(`/${language}/home`);
        }
    }, [tickets]);


    const formattedDate = (date) => {
        let localLanguageString = 'de-DE';

        if (language === "en") {
            localLanguageString = 'en-US'
        }
        else if (language === "fr") {
            localLanguageString = 'fr-FR'
        }

        let formattedDate = date.toLocaleDateString(localLanguageString, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        return formattedDate;
    }


    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            const bookedTickets = updatedOrder.tickets;

            // Booked Tickets
            const updatedTickets = await Promise.all(
                bookedTickets.map(async (ticket) => {

                    const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                    const ticketTypeInfos = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeInfo = ticketTypeInfos.find(info => info.languageId === languageId) ?? ticketTypeInfos[0];

                    var date = new Date(ticketType.start);
                    setEventDate(formattedDate(date));

                    let isAllInclusiveTicket = ticketTypeInfo.name.toLowerCase().includes("all in one");

                    if (ticket.timeOfEntryFrom && ticket.timeOfEntryTo) {
                        setEntryTime(`${ticket.timeOfEntryFrom.slice(0, -3)} - ${ticket.timeOfEntryTo.slice(0, -3)}`)
                    }

                    //display all events when it's an "all inclusive" ticket
                    if (isAllInclusiveTicket) {
                        setShowMariaAbramovicEvent(true);
                        setShowWongVanGoghEvent(true);
                        setShowCollectionEvent(true);
                    }
                    else {
                        var eventInfos = await getEventInfosByEventId(ticketType.eventId);
                        const eventInfo = eventInfos.eventInfos.find(info => info.languageId === languageId) ?? eventInfos[0];

                        if (eventInfo) {
                            //check what event we bought and display the eent accordingly
                            if (eventInfo.name.toLowerCase().includes("marina abramovic")) {
                                setShowMariaAbramovicEvent(true);
                            }
                            else if (eventInfo.name.toLowerCase().includes("van gogh")) {
                                setShowWongVanGoghEvent(true);
                            }
                        }
                    }

                    return { ...ticket, ticketType: ticketType, info: ticketTypeInfo, sortOrder: ticketType.sortOrder }
                })
            )

            setOrder(updatedOrder);
            setTickets(updatedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                navigate(`/${language}/home`);
            }
        }
    }

    async function loadCountries() {
        let countries = await requestCountries();

        if (countries == null) navigate(`/${language}/home`);

        //first add switzerland, germany and austria
        const indexCH = countries.findIndex((c) => c.id === 176);
        const ch = countries[indexCH];
        countries.splice(indexCH, 1);

        var indexDE = countries.findIndex((c) => c.id === 229);
        const de = countries[indexDE];
        countries.splice(indexDE, 1);

        var indexAU = countries.findIndex((c) => c.id === 228);
        const au = countries[indexAU];
        countries.splice(indexAU, 1);

        countries.unshift({ id: "", name: "---------" });
        countries.unshift(au);
        countries.unshift(de);
        countries.unshift(ch);

        setCountries(countries);

        setLoading(false);
    }

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestCountries = async () => {
        var response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
        return response.data;
    };

    const removeTicket = async (ticketId) => {
        if (order != null && order.tickets != null) {
            let ticketTypeIdsToRemove = order.tickets
                .filter(ticket => ticket.id === ticketId)
                .map(ticket => ticket.id);

            // DELETE request to remove tickets
            await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                data: { TicketsToRemove: ticketTypeIdsToRemove }
            }).then((res) => {
                loadOrder(order.id)
            })
        }
    };

    const onSubmit = () => {
        addAddressToBasket();
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^([a-z A-Z������]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.street) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^([a-z A-Z������ 0-9 - ]{1,70})$/.test(user.country) &&
        /^\S+@\S+\.\S+$/.test(user.email) &&
        /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
        user.email === user.confirmemail && acceptTermsAndConditions;

    const addAddressToBasket = () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            street: user.street,
            postCode: user.place,
            city: user.city,
            countryId: user.country,
            email: user.email,
            languageId: languageId
        };
        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                /* redirect to checkout page if its the last interation */
                if (order?.totalPrice === 0) {
                    changePaymentTypeToFree();
                } else {
                    navigate(`/${language}/checkout`);
                }
            })
            .catch((error) => {
            });
    };

    const changePaymentTypeToFree = async () => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                confirmShopbasketByOrderId(orderId);
            });
    };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            navigate(`/${language}/confirmation/${orderId}`);
        } catch (error) {
            console.error(error)
        }
    };

    const mapTickets = () => {
        let ticketsSorted = [];

        if (tickets && tickets.length > 0) {
            ticketsSorted = [...tickets].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (tickets &&
            tickets.length > 0 &&
            ticketsSorted.map((t, index) => (
                (<div key={index} className="row padding-25">
                    <div className="col-6 col-md-7 text-20-24 black-color text-start d-flex align-items-center">
                        <label>
                            1 x {t.info?.name}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                        <label>
                            {t.currency}
                        </label>
                    </div>
                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-start align-items-center">
                        <label className="price-label text-end">
                            {t.price.toFixed(2)}
                        </label>
                    </div>
                    <div className="col-4 col-md-1 text-20-24 black-color d-flex justify-content-end align-items-center">
                        <button
                            type="button"
                            className="ticket-remove-btn"
                            aria-label="Close"
                            onClick={() => removeTicket(t.id)} >
                            <img src={imgClose} alt="X" />
                        </button>
                    </div>

                </div>)
            ))
        );
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            {loading
                ? (
                    <Loading
                        alignment="center"
                        color="#d3d3d3"
                        bgColor="transparent"
                        position="fixed"
                        top="50%"
                        left="50%" />
                ) : (
                    <>
                        <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={true} pagePath="shipping" />
                        <main className="flex-fill">
                            <div className="container page-wrapper">
                                <div className="progress-container">
                                    <div className="progress-step">
                                        <div>
                                            <div className="image cursor-pointer ">
                                                <img src={imgIconProgressTick} alt="Step 1" onClick={() => navigate(-2)} />
                                            </div>
                                            <div className="step-number text-wrap visible-desktop">{resources?.DateAndTime}</div>
                                        </div>
                                        <div className="line"></div>
                                    </div>

                                    <div className="progress-step completed">
                                        <div>
                                            <div className="image cursor-pointer ">
                                                <img src={imgIconProgressTick} alt=" Step 2" onClick={() => navigate(-1)} />
                                            </div>
                                            <div className="step-number text-wrap visible-desktop">{resources?.TicketSelection}</div>
                                        </div>
                                        <div className="line"></div>
                                    </div>

                                    <div className="progress-step active">
                                        <div>
                                            <div className="image">
                                                <img src={imgIconProgress3} alt="Step 3" />
                                            </div>
                                            <div className="step-number text-wrap visible-desktop">{resources?.SummaryAndContact}</div>
                                        </div>
                                        <div className="line"></div>
                                    </div>

                                    <div className="progress-step">
                                        <div>
                                            <div className="image">
                                                <img src={imgIconProgressGrey} alt="Step 4" />
                                            </div>
                                            <div className="step-number text-wrap visible-desktop">{resources?.PaymentAndDelivery}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-8 padding-0">
                                        <p className="text-50-55 black-color font-500">{resources?.SummaryText}</p>
                                    </div>
                                </div>
                                <div className="row margin-top-30 margin-bottom-55">
                                    <div className="col-md-12 div-container-blue padding-left-25 padding-right-25 padding-top-20">
                                        {showMariaAbramovicEvent &&
                                            <>
                                                <p className="text-30-36-bold black-color ">
                                                    {resources.MariaAbramovic}
                                                </p>
                                                <p className="text-20-22-medium black-color mt-3">
                                                    {eventDate !== "" && eventDate} {entryTime !== "" && (entryTime + " " + resources?.SummaryHourText + " " + resources?.SummaryTimeFormat)}
                                                </p>
                                            </>}
                                        {showWongVanGoghEvent &&
                                            <>
                                                <p className="text-30-36-bold black-color mt-2">
                                                    {resources.WongVangogh}
                                                </p>
                                                <p className="text-20-22-medium black-color mt-3">
                                                    {eventDate !== "" && eventDate} {resources.SummaryFreeTime}
                                                </p>
                                            </>}
                                        {showCollectionEvent &&
                                            <>
                                                <p className="text-30-36-bold black-color mt-2">
                                                    {resources.Collection}
                                                </p>
                                                <p className="text-20-22-medium black-color mt-3">
                                                    {eventDate !== "" && eventDate} {resources.SummaryFreeTime}
                                                </p>
                                            </>}
                                    </div>
                                </div>

                                {mapTickets()}

                                <div className="padding-left-25 padding-right-25" >
                                    <div className="mt-3 grey-line" ></div>
                                </div>

                                <div className="row mt-5 mb-5 padding-left-25 padding-right-25">
                                    <div className="col-md-7 col-6 text-20-24 black-color d-flex justify-content-start align-items-center">{resources.Total}:</div>
                                    <div className="col-1 col-md-2 text-20-24 black-color d-flex justify-content-end align-items-center">
                                        <label className="font-600">
                                            CHF
                                        </label>
                                    </div>
                                    <div className="col-1 col-md-1 text-20-24 black-color d-flex justify-content-start align-items-center">
                                        <label className="font-600 price-label text-end"> {order && order.total && order.total !== null ? (
                                            <span>{(order.total).toFixed(2)}</span>
                                        ) : (
                                            <span>0.00</span>
                                        )}
                                        </label>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-12">
                                        <p className="text-50-55 black-color font-500">{resources.Contact}</p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-20-24">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.Salutation}</label>
                                    </div>
                                    <div className="col-8 col-md-9">
                                        <select
                                            className="form-control form-select"
                                            aria-label={resources?.ShippingAnrede}
                                            name="salutation"
                                            value={user.salutation}
                                            onChange={(e) => onInputChange(e)}>
                                            <option>{resources?.PleaseSelect}</option>
                                            <option value={resources?._Mr}>{resources?._Mr}</option>
                                            <option value={resources?._Mrs}>{resources?._Mrs}</option>
                                            <option value={resources?._Others}>{resources?._Others}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.FirstName}</label>
                                    </div>
                                    <div className="col-8 col-md-9">
                                        <input
                                            type="text"
                                            name="firstname"
                                            className="form-control"
                                            value={user.firstname}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.Name}</label>
                                    </div>
                                    <div className="col-8 col-md-9">
                                        <input
                                            type="text"
                                            name="lastname"
                                            className="form-control"
                                            value={user.lastname}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.Company}</label>
                                    </div>
                                    <div className="col-8 col-md-9">
                                        <input
                                            type="text"
                                            name="company"
                                            className="form-control"
                                            value={user.company}
                                            onChange={(e) => onInputChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.Street}</label>
                                    </div>
                                    <div className="col-8 col-md-9">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control"
                                            value={user.street}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.Post}</label>
                                    </div>
                                    <div className="col-md-9 col-4">
                                        <input
                                            type="text"
                                            name="place"
                                            className="form-control"
                                            value={user.place}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources.City}</label>
                                    </div>
                                    <div className="col-md-9 col-4">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            value={user.city}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources?.Country}</label>
                                    </div>
                                    <div className="col-md-9 col-8 ">
                                        <select
                                            className="form-control"
                                            aria-label="Land"
                                            name="country"
                                            value={user.country}
                                            onChange={(e) => onInputChange(e)}>
                                            {countries.map((c, index) => (
                                                <option value={c.id} key={index}>
                                                    {c.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources?.ShippingEmail}</label>
                                    </div>
                                    <div className="col-md-9 col-8 ">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            value={user.email}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-3 col-4 dark-grey-color d-flex flex-column justify-content-center">
                                        <label>{resources?.ShippingEmailConfirmation}</label>
                                    </div>
                                    <div className="col-md-9 col-8">
                                        <input
                                            type="text"
                                            name="confirmemail"
                                            className="form-control"
                                            value={user.confirmemail}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 text-20-24 ">
                                    <div className="col-md-12 mb-3">
                                        <div className="form-check mb-4 d-flex align-items-center">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                checked={acceptTermsAndConditions}
                                                onChange={(e) => setAcceptTermsAndConditions(e.target.checked)}
                                                id="termsCheckbox"
                                            />
                                            <label htmlFor="termsCheckbox" className="form-check-label text-16-18 dark-grey-color"
                                                dangerouslySetInnerHTML={{ __html: resources.TermsAndConditionsConfirm }}>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4 mb-4">
                                    <div className="col-6">
                                        <button className="btn transperant-grey-button" onClick={() => navigate(-1)}>
                                            {resources?._Back}
                                        </button>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button
                                            className="btn grey-button"
                                            disabled={!validation}
                                            onClick={() => onSubmit()}
                                        >
                                            {resources?.Continue}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <Footer />
                    </>
                )}
        </div>
    );
};

export default Step20_Shipping;