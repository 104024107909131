import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import imgLogo from "../../img/logo.png";

import "../../css/navbar.css"

const Navbar = (props) => {
    const [resources, setResources] = useState({});
    const [scrollingUp, setScrollingUp] = useState(true);

    let navigate = useNavigate();

    const { language } = useParams();


    useEffect(() => {

        let lastScrollY = window.pageYOffset;

        const handleScroll = () => {
            if (window.pageYOffset > lastScrollY) {
                setScrollingUp(false); // User is scrolling down
            } else {
                setScrollingUp(true); // User is scrolling up
            }
            lastScrollY = window.pageYOffset;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        requestResources();

    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.home);
            })
            .catch((error) => console.error(error.response.data));
    };

    let languageNavPath = props.pagePath;

    if (props.eventId > 0) {
        languageNavPath = props.pagePath + "/" + props.eventId
    }

    return (
        <>
            <div className={`navbar-container ${scrollingUp ? 'navbar-visible' : 'navbar-hidden'}`}>
                {/* Navbar */}
                <nav className="navbar navbar-dark bg-dark">
                    <div className="container-fluid page-wrapper">
                        <a className="navbar-brand" href={`/${language}/home`}>
                            <div className="navbar-brand-img">
                                <img className="navbar-brand-img img-fluid d-flex align-items-center justify-content-end" src={imgLogo} alt="Kunsthaus Z�rich" />
                            </div>
                            <svg className="navbar-brand-img-mobile align-items-center justify-content-end" xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 1000 800">
                                <g id="Ebene_1">
                                    <polygon
                                        className="cls-1"
                                        points="812.1 704.9 999 704.9 648.6 392.2 946.9 126.3 769 126.3 531.8 340.1 373.7 340.1 373.7 126.3 246.1 126.3 246.1 704.9 373.7 704.9 373.7 449.7 526.4 449.7 812.1 704.9"
                                        style={{ fill: '#fff' }}
                                    />
                                </g>
                            </svg>
                        </a>
                        <div className="navbar-center d-flex align-items-center justify-content-end" style={{ height: "100%" }}>
                            <span className="nav-title">Tickets</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                            {props.isShoppingCartVisible &&
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className={`nav-shop-basket-container ${props.numberOfTickets > 0 ? 'cursor-pointer' : ''}`}
                                        onClick={props.numberOfTickets > 0 ? (e) => navigate(`/${language}/shipping`) : undefined}>
                                        <svg fill="#ffffff" className="nav-shop-basket" viewBox="0 -32 576 576" xmlns="http://www.w3.org/2000/svg"><path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z" /></svg>
                                    </div>
                                    {props.numberOfTickets > 0 &&
                                        <span className="cart-quantity">
                                            {props.numberOfTickets}
                                        </span>
                                    }
                                </div>
                            }
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="23" viewBox="0 0 35 30">
                                    <rect x="0" y="0" width="45" height="3" fill="#fff" />
                                    <rect x="0" y="11" width="45" height="3" fill="#fff" />
                                    <rect x="0" y="22" width="45" height="3" fill="#fff" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </nav>

                {/* Offcanvas Sidebar */}
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
                    <div className="offcanvas-header">
                        <div className="offcanvas-title" id="offcanvasMenuLabel">
                            <span
                                className={`pe-3 language ${language === "de" ? "underline" : ""}`}
                                onClick={(e) => navigate(`/de/${languageNavPath}`)}
                            >
                                de
                            </span>
                            <span
                                className={`pe-3 language ${language === "en" ? "underline" : ""}`}
                                onClick={(e) => navigate(`/en/${languageNavPath}`)}
                            >
                                en
                            </span>
                            <span
                                className={`pe-3 language ${language === "fr" ? "underline" : ""}`}
                                onClick={(e) => navigate(`/fr/${languageNavPath}`)}
                            >
                                fr
                            </span>
                        </div>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">

                        <div className="accordion" id="accordion-information-nav">
                            <div className="accordion-item accordion-item-nav">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button collapsed accordion-button-nav"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        <label className="accordion-nav-button-text">
                                            {resources?.NavbarOpeningTime}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion-information-tariffe">
                                    <div className="accordion-body accordion-body-nav">
                                        <ul className="list-unstyled">
                                            <li className="accordion-nav-sub">{resources?.NavbarOpeningTime1Title}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text1}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text2}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text3}</li>
                                        </ul>
                                        <span>&nbsp;</span>
                                        <ul className="list-unstyled">
                                            <li className="accordion-nav-sub">{resources?.NavbarOpeningTime2Title}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text2}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text3}</li>
                                        </ul>
                                        <span>&nbsp;</span>
                                        <ul className="list-unstyled">
                                            <li className="accordion-nav-sub">{resources?.NavbarOpeningTime3Title}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime3Text1}</li>
                                        </ul>
                                        <span>&nbsp;</span>
                                        <ul className="list-unstyled">
                                            <li className="accordion-nav-sub">{resources?.NavbarOpeningTime4Title}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime4Text1}</li>
                                        </ul>
                                        <span>&nbsp;</span>
                                        <ul className="list-unstyled">
                                            <li className="accordion-nav-sub">{resources?.NavbarOpeningTime5Title}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text1}</li>
                                            <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text2}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar