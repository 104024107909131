import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import "../../css/style.css"

const Footer = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.home);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <>
            <footer className="container page-wrapper d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-10">
                    <span className="text-18-21 color-dark-grey mb-3 me-3 mb-md-0 footer-text">{resources?.Footer}</span>
                    <a className="text-18-21 color-dark-grey mb-3 me-3 mb-md-0 footer-text" target="_blank" rel="noreferrer" href={resources?.FooterImpressumUrl}>
                        {resources?.FooterImpressum}
                    </a>
                    <a className="text-18-21 color-dark-grey mb-3 me-3 mb-md-0 footer-text" target="_blank" rel="noreferrer" href={resources?.FooterAgbUrl}>
                        {resources?.FooterAgb}
                    </a>
                    <a className="text-18-21 color-dark-grey mb-3 me-3 mb-md-0 footer-text" target="_blank" rel="noreferrer" href={resources?.FooterDataProtectionUrl}>
                        {resources?.FooterDataProtection}
                    </a>
                    <a className="text-18-21 color-dark-grey mb-3 mb-md-0 footer-text" target="_blank" rel="noreferrer" href={resources?.FooterCookiesUrl}>
                        {resources?.FooterCookies}
                    </a>
                </div>

                {/*<ul className="nav col-12 col-md-2 list-unstyled d-flex justify-content-start justify-content-md-end social-media">*/}
                {/*    <li className="ms-3">*/}
                {/*        <a className="text-muted" href="https://facebook.com/kunsthauszuerich/">*/}
                {/*            <svg width="19" height="35" xmlns="http://www.w3.org/2000/svg"><path d="M18.859.252v5.553h-3.427c-1.251 0-2.095.253-2.532.758-.437.504-.655 1.262-.655 2.271v3.975h6.396l-.852 6.226h-5.544V35H5.566V19.035H0V12.81h5.566V8.224c0-2.608.757-4.63 2.27-6.068C9.349.719 11.365 0 13.882 0c2.14 0 3.798.084 4.977.252" fill="#333333" fill-rule="evenodd" /></svg>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li className="ms-3">*/}
                {/*        <a className="text-muted" href="https://www.instagram.com/kunsthauszuerich/">*/}
                {/*            <svg width="36" height="35" xmlns="http://www.w3.org/2000/svg"><g fill="#333333" fill-rule="evenodd"><path d="M17.733 11.428c-3.307 0-5.998 2.724-5.998 6.072s2.69 6.072 5.998 6.072c3.308 0 5.998-2.724 5.998-6.072s-2.69-6.072-5.998-6.072m0 14.897c-4.807 0-8.717-3.96-8.717-8.825 0-4.866 3.91-8.825 8.717-8.825s8.718 3.959 8.718 8.825-3.91 8.825-8.718 8.825M27.25 8.47a.035.035 0 0 0-.034.036c0 .04.07.04.07 0a.035.035 0 0 0-.035-.035m0 2.36c-1.266 0-2.296-1.043-2.296-2.325 0-1.281 1.03-2.324 2.296-2.324 1.266 0 2.296 1.043 2.296 2.324 0 1.282-1.03 2.324-2.296 2.324" /><path d="M9.187 2.753c-3.32 0-6.022 2.734-6.022 6.095v17.304c0 3.36 2.701 6.095 6.022 6.095H26.28c3.32 0 6.021-2.734 6.021-6.095V8.848c0-3.36-2.701-6.095-6.021-6.095H9.187zM26.28 35H9.187c-4.82 0-8.741-3.97-8.741-8.848V8.848C.446 3.97 4.366 0 9.186 0H26.28c4.82 0 8.74 3.97 8.74 8.848v17.304C35.02 31.03 31.1 35 26.28 35z" /></g></svg>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li className="ms-3">*/}
                {/*        <a className="text-muted footer-text" href="https://www.youtube.com/user/KunsthausZurich">*/}
                {/*            <svg width="42" height="30" xmlns="http://www.w3.org/2000/svg"><path d="M41.087 4.658A5.294 5.294 0 0 0 37.433.855C34.237 0 21.325 0 21.325 0S8.414 0 5.175.897a5.222 5.222 0 0 0-3.653 3.804C.651 8.034.651 15 .651 15s0 6.966.871 10.3a5.294 5.294 0 0 0 3.654 3.803C8.414 30 21.326 30 21.326 30s12.91 0 16.149-.897a5.222 5.222 0 0 0 3.653-3.804C42 21.966 42 15 42 15s-.042-6.966-.913-10.342zM17.19 21.6V8.4L27.823 15 17.19 21.6z" fill="#333333" /></svg>*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </footer>
        </>
    )
}

export default Footer